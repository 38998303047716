import { t } from "i18next";

export const handleReduceDataIds = (
    array: any[],
    handleId: (elem: any) => string
) =>
    array.reduce((obj: any, item: any) => {
        obj[handleId(item)] = item;
        return obj;
    }, {});

export const formatNumber = (value: number) =>
    value < 3
        ? t("below")
        : value.toLocaleString(undefined, {
              minimumFractionDigits: 1,
              maximumFractionDigits: 1
          });
